import React from "react"

import { useQuery } from "@apollo/react-hooks"
import { graphql } from "gatsby"
import { ACTIVITY_PROGRESS } from "../gql"

import LayoutContainer from "../components/layout"
import Breadcrumb from "../components/breadcrumb"
import { richTextToReact } from "../utilities/richTextRenderer"
import ActivityProgress from "../components/activity-progress"
import { useActivityMutations } from "../utilities/hooks"
import useCurrentLang from "../hooks/useCurrentLang"
import { Trans, withTranslation } from "react-i18next"

export const activityQuery = graphql`
  query Activity($id: String) {
    contentfulActivity(id: { eq: $id }) {
      contentful_id
      title
      title_cs
      title_de
      title_es
      title_fr
      title_hu
      title_it
      title_ja
      title_ko
      title_nl
      title_pl
      title_pt
      title_th
      title_hk
      points
      content {
        json
      }
      content_cs {
        json
      }
      content_de {
        json
      }
      content_es {
        json
      }
      content_fr {
        json
      }
      content_hu {
        json
      }
      content_it {
        json
      }
      content_ja {
        json
      }
      content_ko {
        json
      }
      content_nl {
        json
      }
      content_pl {
        json
      }
      content_pt {
        json
      }
      content_th {
        json
      }
      content_hk {
        json
      }
      illustration {
        file {
          url
        }
      }
      inspiration {
        json
      }
      journey {
        color
      }
    }
  }
`

const ActivityTemplate = ({ data, location }) => {
  const { code: langCode } = useCurrentLang()
  const { data: progressData } = useQuery(ACTIVITY_PROGRESS, {
    variables: {
      contentfulId: data.contentfulActivity.contentful_id,
    },
    fetchPolicy: "cache-and-network",
  })

  const {
    startActivity,
    doActivity,
    redoActivity,
    stopActivity,
    undoActivity,
    loading: progressLoading,
    data: updatedData,
  } = useActivityMutations(data.contentfulActivity.contentful_id)

  const {
    inspiration,
    illustration,
    journey,
    points,
    title,
    content,
    ...activityRest
  } = data.contentfulActivity

  let backgroundColor = "white"
  if (journey) {
    backgroundColor = journey[0].color
  }

  const isRiddle = location.pathname.includes("riddle")

  const contentDisplay =
    activityRest[`content_${langCode === "zh" ? "hk" : langCode}`] || content

  let htmlContent = null
  if (contentDisplay && contentDisplay.json) {
    htmlContent = richTextToReact(contentDisplay.json)
  }

  let htmlInspiration = null
  if (inspiration && inspiration.json) {
    htmlInspiration = richTextToReact(inspiration.json)
  }

  const onStartActivity = e => {
    // Set activity active status to true
    startActivity()
  }
  const onDoActivity = e => {
    // Increment activity progress by 1
    doActivity()
  }

  // const onRedoActivity = e => {
  //   // Start the activity over by resetting progress to 0
  //   redoActivity()
  // }

  const onUndoActivity = e => {
    // Decrement activity progress by 1
    undoActivity()
  }
  // const onStopActivity = e => {
  //   // Set activity active status to false
  //   stopActivity()
  // }

  return (
    <LayoutContainer
      path={location.pathname}
      style={{ backgroundColor: backgroundColor }}
    >
      <Breadcrumb location={location} />
      <article>
        <div className="row">
          <div className="col l8 offset-l2 s12">
            {illustration && (
              <img
                className="activity__image"
                src={illustration.file.url}
                alt=""
              />
            )}
            <div className="activity__title__wrapper">
              <h1 className="big-title uppercase" style={{ maxWidth: 700 }}>
                {activityRest[`title_${langCode === "zh" ? "hk" : langCode}`] ||
                  title}
              </h1>
              <div className="progress__point__circle">{points}</div>
            </div>
            <div className="content text">{htmlContent}</div>
            <div>
              <div className="activity__wrapper">
                {progressData && progressData.activity.active ? (
                  <>
                    <div>&nbsp;</div>
                    {/* <button
                      className="button button--activity"
                      onClick={onStopActivity}
                    >
                      <Trans i18nKey={"Activity.stop"} />
                    </button> */}
                  </>
                ) : (
                  <>
                    {/* If the activity has been completed, allow the user to start over. */}
                    {progressData && progressData.activity.completed ? (
                      <>
                        <div>&nbsp;</div>
                        {/* to remove redo functionality from UI */}
                        {/* <button
                        className="button button--activity"
                        onClick={onRedoActivity}
                      >
                        <Trans i18nKey={"Activity.redo"} />
                      </button> */}
                      </>
                    ) : !isRiddle ? (
                      <button
                        className="button button--activity"
                        onClick={onStartActivity}
                      >
                        <Trans i18nKey={"Activity.start"} />
                      </button>
                    ) : null}
                  </>
                )}
                {progressData &&
                  (progressData.activity.active ||
                    progressData.activity.completed) && (
                    <ActivityProgress
                      progress={
                        (updatedData && updatedData.progress) ||
                        progressData.activity.progress
                      }
                      progressLeft={
                        (updatedData && updatedData.progressLeft) ||
                        progressData.activity.progressLeft
                      }
                      progressLoading={progressLoading}
                      undoActivity={onUndoActivity}
                      doActivity={onDoActivity}
                      isRiddle={isRiddle}
                    ></ActivityProgress>
                  )}
              </div>
            </div>
            <div className="content content__inspiration text">
              {htmlInspiration}
            </div>
          </div>
        </div>
      </article>
    </LayoutContainer>
  )
}

export default withTranslation()(ActivityTemplate)
