import React from "react"
import { Link } from "gatsby"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Video from "../components/video"

const Bold = ({ children }) => <strong>{children}</strong>
const Text = ({ children }) => <p>{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },

  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [INLINES.ENTRY_HYPERLINK]: (node, children) => (
      <Link to={`/${node.data.target.fields.slug["en-US"]}`}>{children}</Link>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <a target="_blank" rel="noreferrer noopener" href={`${node.data.uri}`}>
        {children}
      </a>
    ),
    [INLINES.EMBEDDED_ENTRY]: node => {
      // Currently always assumes that an embedded entry is a vimeoUrl type.
      // Could be improved to check what type it is if we support more embedded entries in the future.
      const vimeoUrl = node.data.target.fields.vimeoUrl["en-US"]
      return <Video videoUrl={vimeoUrl}>Test</Video>
    },
    [BLOCKS.EMBEDDED_ASSET]: node => {
      return (
        <img
          src={`${node.data.target.fields.file["en-US"].url}`}
          alt={node.data.target.fields.file["en-US"].title || "Content"}
        />
      )
    },
  },
}

export const richTextToReact = json => {
  const result = documentToReactComponents(json, options)
  return result
}
