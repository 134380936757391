import React, { useRef, useState } from "react"
import playIcon from "./../assets/icons/play.svg"

const Video = ({ videoUrl, thumbnail = "" }) => {
  const video = useRef()
  const [isPlaying, setIsPlaying] = useState(false)

  const togglePlay = () => {
    setIsPlaying(!isPlaying)

    if (isPlaying) {
      return video.current.pause()
    }
    return video.current.play()
  }

  return (
    <div className="video">
      <div className="video__wrapper">
        <video
          poster={thumbnail}
          playsInline
          className="video__frame"
          ref={video}
          onClick={() => togglePlay()}
        >
          <source src={videoUrl} />
        </video>
        {isPlaying || (
          <img
            className="video__play-icon"
            src={playIcon}
            alt="Play"
            onClick={() => togglePlay()}
          />
        )}
      </div>
    </div>
  )
}

export default Video
